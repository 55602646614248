import compose from 'core/libs/helpers/compose.js';
import {Field, withFormik} from 'formik';
import FormInputField from 'modules/common/FormInputField';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import * as Yup from 'yup';
import {resetPasswordEmail} from '../../actions/reset-password-email';
import {fixedHeight} from '../styles.scss';

const ResetPasswordForm = (props) => {
  const {
    handleSubmit,
    intl: {formatMessage},
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="mt3 pt3 pb4">
        <Field
          name="email"
          type="email"
          data-testid="email"
          component={FormInputField}
          placeholder={formatMessage({id: 'label_email_adress'})}
          className={`${fixedHeight} input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr`}
        />
      </div>
      <button
        aria-label="Reset password"
        type="submit"
        data-testid="reset-password"
        className="button-reset w-auto-ns w-100 bn f6 fw6 white pv3 mt1 ph2 ph3-m ph5-l br2 bg-blue bg-animate hover-bg-dark-blue pointer"
      >
        <FormattedMessage id="reset_password" defaultMessage="Reset Password" />
      </button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  intl: PropTypes.object,
};

const enhance = compose(
  connect(),
  injectIntl,
  withFormik({
    mapPropsToValues: () => {
      return {
        email: '',
      };
    },
    handleSubmit: (values, {props}) => {
      props.dispatch(resetPasswordEmail(values));
    },
    validationSchema: ({intl: {formatMessage}}) =>
      Yup.object().shape({
        email: Yup.string()
          .email(formatMessage({id: 'error_email_invalid'}))
          .required(formatMessage({id: 'error_email'})),
      }),
  }),
);

export default enhance(ResetPasswordForm);
