import notification from 'core/libs/helpers/notification';

export const resetPasswordEmail = (values) => {
  const {PARTNER} = process.env;
  const data = new FormData();
  data.append('email', values.email);
  data.append('partner', PARTNER);

  return {
    types: [
      'RESET_PASSWORD_EMAIL_REQUEST',
      'RESET_PASSWORD_EMAIL_SUCCESS',
      'RESET_PASSWORD_EMAIL_FAILURE',
    ],
    config: {
      url: 'password-reset/',
      method: 'POST',
      data,
    },
    callback: (success, result) => {
      if (success) {
        notification({
          id: 'reset_password_instructions',
          type: 'success',
        });
      } else if (result && result.response && result.response.status >= 400) {
        const notificationMessage = Object.values(
          result.response.data,
        ).toString();
        notification({message: notificationMessage});
      } else {
        notification({message: 'There was an error querying the api'});
      }
    },
  };
};

export default resetPasswordEmail;
