import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import BarmejLogo from 'theme/assets/images/barmej-logo.svg';
import KfasLogo from 'theme/assets/images/kfas-logo.svg';
import ResetPasswordEmailForm from '../components/ResetPasswordEmailForm';

const ResetPassword = () => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {PARTNER} = process.env;
  return (
    <Container
      authenticated={false}
      className="w-90-ns w-100 center pv4 mb2 ph5-l"
    >
      <Head
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_reset_password'
              : 'partner_helmet_reset_password',
        })}
      />
      <div className="tc pb5">
        {PARTNER === 'KFAS' && (
          <img src={KfasLogo} height="100" alt="kfas logo" />
        )}
        {PARTNER === 'BARMEJ' && (
          <img src={BarmejLogo} height="50" alt="barmej logo" />
        )}
      </div>
      <div className="pv5 ph4 ba b--light-gray bw1 br2 bg-white">
        <h3 className="f3 fw6 mb3 tr" data-cy="reset-password-title">
          <FormattedMessage id="reset_password" />
        </h3>
        <p>
          <FormattedMessage
            id="send_email_to_reset_password"
            defaultMessage="Enter an email address and we’ll send you an email with instructions to reset your password"
          />
          .
        </p>
        <div className="w-70-l">
          <ResetPasswordEmailForm />
        </div>
      </div>
    </Container>
  );
};

export default ResetPassword;
