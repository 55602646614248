import {navigate} from 'gatsby';
import React, {Component} from 'react';
import {connect} from 'react-redux';

export default (ChildComponent) => {
  class RedirectIfAuthenticated extends Component {
    state = {
      redirecting: false, // eslint-disable-line react/no-unused-state
    };

    static getDerivedStateFromProps(
      {authenticated, isSignUp, locations},
      {redirecting},
    ) {
      // should not redirect when we are in sign up process
      if (authenticated && !redirecting && !isSignUp) {
        const lastLocationNotAuth = locations
          .slice()
          .reverse()
          .find((location) => !/^\/auth/.test(location));
        if (lastLocationNotAuth && !lastLocationNotAuth.includes('tracks')) {
          navigate(lastLocationNotAuth);
        } else {
          navigate('/dashboard');
        }
        return {redirecting: true};
      }

      return null;
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  return connect(({auth, logger}) => ({
    authenticated: auth.authenticated,
    isSignUp: auth.isSignUp,
    locations: logger.locations,
  }))(RedirectIfAuthenticated);
};
